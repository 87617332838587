import React from "react";
import Layout from "../components/layout";
import SEO from "../components/SEO";
import HTML from "../components/HTML";
import FindDealer from "../components/findDealer/findDealer";
import Jumbotron from "../components/jumbotron/Jumbotron";
import WrappedFindDealerMobile from "../components/findDealer/wrappedFindDealerMobile";
import InstagramFeed from "../components/feed/InstagramFeed";

function SellYourVehicle(props) {
  const { pageContext } = props;
  const { pageContent, dealerInfo, googleAPIkey } = pageContext;
  const dealerLocals = props.pageContext.dealers;
  const fixedDealerList = pageContent.SellYourVehicle.dealerList ? pageContent.SellYourVehicle.dealerList.split(",") : [];

  let components = [
    <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} />,
    <Jumbotron key={"Jumbotron"} data={{ ...pageContent.Jumbotron }} isSellYourVehicle={true} />,
    <WrappedFindDealerMobile
      pageContent={pageContent}
      pageContext={pageContext}
      dealerInfo={dealerInfo}
      data={{ order: 1, visible: true }}
      isSellYourVehicle={true}
    />,
    <FindDealer
      data={{ ...pageContent.SellYourVehicle }}
      dealerInfo={{ ...dealerInfo }}
      dealerLocals={dealerLocals}
      googleAPIkey={googleAPIkey}
      isSellYourVehicle={true}
      fixedDealerList={fixedDealerList}
    />,
    <InstagramFeed data={pageContent.InstagramSection} />,
  ];

  if (pageContent.hasOwnProperty("ArrayRawHTML")) {
    const { arrayRawHTML } = pageContent.ArrayRawHTML;
    const rawComponents = arrayRawHTML.map((element) => {
      return (
        <HTML
          data={{
            order: Number(element.order),
            visible: element.visible,
            rawHTML: element.rawHtml,
          }}
          key={"HTML"}
        />
      );
    });
    components.push(...rawComponents);
  }

  let sortedComponents = components.sort((a, b) =>
    parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
  ); // sorts the components based on json data
  // filters out the components that are not visible
  let visibleComponents = sortedComponents.filter((component) => {
    return component.props.data.visible === true;
  });

  return (
    <Layout>
      <div class="sellVehicle">
      {visibleComponents}
      </div>
    </Layout>
  );
}

export default SellYourVehicle;
